.Wave {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  &--games {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    flex-grow: 1;
    padding-right: 1em;

    &--game {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin-bottom: 2em;

      &--VS {
        margin-left: 3em;
        margin-right: 3em;
      }

      &--team {
        cursor: pointer;
        width: 10em;
        height: max-content;
        display: flex;
        flex-direction: column;
        padding: 1em;
        border: 3px solid transparent;

        &--name {
          word-wrap: break-word;
        }

        &_winner {
          border: 3px solid goldenrod;
        }

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }

  &--buttons {
    margin-top: 3em;
    display: flex;
    margin-bottom: 2em !important;
    flex-direction: row;
    justify-content: space-between;

    &--button {
      height: 3em;
      margin-right: 2em !important;
    }
  }

  &--confirm-end {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--text {
      margin-left: 1em;
      margin-right: 1em;
    }

    &--buttons {
      margin: 1em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
