@import '../../colors.scss';

.LandingPage {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  &--start {
    flex-grow: 1.2;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--logo {
      position: absolute;
      display: flex;
      align-items: center;
      flex-grow: 1;
      height: 2em;
      top: 10%;
    }

    &--start {
      display: flex;
      flex-grow: 6;
      align-items: center;
      flex-direction: column;
      text-align: center;
      justify-content: center;

      h1 {
        width: 70%;
        font-size: 2em;
        color: $color-blue;
        margin: 0;
      }

      h2 {
        font-size: 1.5em;
        color: $color-blue-light;
      }

      &--form {
        margin-top: 2em;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

        &--mail {
          height: 3em;
          padding-bottom: 2em !important;
          border-radius: 2em !important;
          width: 70%;
        }

        &--button {
          height: 56px !important;
          border-radius: 2em !important;
          width: 70%;
        }
      }
    }

    &--contact {
      display: flex;
      flex-grow: 1;
      align-items: center;
      flex-direction: column;
      width: 70%;

      div {
        height: 2.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        color: $color-blue-light;
        font-size: 1.2em;
      }

      div:hover {
        color: $color-blue;
        cursor: pointer;
      }
    }
  }

  &--features {
    flex-grow: 3;
  }
}