.Players {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &--register {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-grow: 1;
  }

  &--nav {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    justify-content: space-around;
    margin-bottom: 2em;

    &--confirmation-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 800px;
      border: 2px solid #000;
      box-shadow: 0 0 10px #000;
      background-color: #fff;

      &--confirmation {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}
