.Configuration {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--action {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 40%;
  }
}
