.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &--configuration {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &--action {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 40%;
  }
}
