.Rank {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 80em;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  &--table {
    width: 50% !important;
    overflow: scroll;
    flex-grow: 1;
  }

  &--endGame {
    align-self: flex-end;
    display: flex;
    margin-bottom: 2em !important;
    margin-right: 2em !important;

    &--button {
      height: 3em;
      align-self: flex-end;
    }
  }
}
